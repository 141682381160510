<template>
  <div class="pa-5">
    <base-snackbar :errors.sync="orderStatusGetErrors"></base-snackbar>
    <base-snackbar :errors.sync="customerGetErrors"></base-snackbar>
    <v-row>
      <v-col lg="3" cols="12">
        <v-autocomplete hide-details="auto" label="Customer" :items="customerList" item-value="id" item-text="company"
          :loading="customerGetting" v-model="customerId" :error-messages="errorMessages.customerId || []"
          :filter="filterCustomerList" outlined="outlined" dense="dense" v-bind="fieldAttributes.customer"><template
            v-slot:selection="{ item }"><span class="mr-2 primary--text">{{ item.id }}</span><small
              class="grey--text">{{
                item.company }}</small></template><template v-slot:item="{ item }"><span class="mr-2 primary--text">{{
                item.id }}</span><small class="grey--text">{{ item.company }}</small></template></v-autocomplete>
      </v-col>
      <v-col lg="3" cols="12">
        <v-text-field label="PO#" outlined dense hide-details="auto" v-model="poNumber" />
      </v-col>
      <v-col lg="3" cols="12">
        <v-autocomplete hide-details="auto" label="Project Code" v-model="projectCodeId" item-value="id"
          item-text="description" :items="projectCodeIndexList" :loading="projectCodeIndexGetting"
          :search-input.sync="searchProjectCode" :error-messages="errorMessages.project_code_id || []"
          clearable="clearable" no-filter="no-filter" outlined="outlined" dense="dense">
          <template v-slot:selection="{ item }"><span class="primary--text">{{ item.project_code }}</span></template>
          <template v-slot:item="{ item }"><span class="primary--text">{{ item.project_code }}</span><small
              class="ml-2 blue-grey--text">{{ item.description }}</small></template>
        </v-autocomplete>
      </v-col>
      <v-col lg="3" cols="12">
        <v-autocomplete hide-details="auto" label="Delivered By" v-model="deliveredBy" :items="computedEmployeeList"
          item-value="id" :error-messages="errorMessages.deliveredBy || []" clearable="clearable" outlined="outlined"
          dense="dense" :filter="employeeFilter" v-bind="fieldAttributes.deliveredBy"><template
            v-slot:selection="{ item }"><span class="primary--text">{{ item.id }}</span></template><template
            v-slot:item="{ item }"><span :class="(item.disabled ? 'grey' : 'primary') + '--text'"><span>{{ item.id
                }}</span></span><small class="ml-2 text-uppercase grey--text">{{ getName(item)
              }}</small></template></v-autocomplete>
      </v-col>
      <v-col lg="3" cols="12">
        <v-autocomplete hide-details="auto" label="CO Deliverer" :items="computedEmployeeList" item-value="id"
          v-model="coDeliverer" :error-messages="errorMessages.coDeliverer || []" clearable="clearable"
          outlined="outlined" dense="dense" :filter="employeeFilter" v-bind="fieldAttributes.coDeliverer">
          <template v-slot:selection="{ item }">
            <span class="primary--text">
              {{ item.id }}
            </span>
          </template>
          <template v-slot:item="{ item }">
            <span :class="(item.disabled ? 'grey' : 'primary') + '--text'">
              <span>
                {{ item.id }}
              </span>
            </span>
            <small class="ml-2 text-uppercase grey--text">
              {{ getName(item) }}
            </small>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col lg="3" cols="12">
        <v-text-field hide-details="auto" label="Order Date" v-model="orderDate"
          :error-messages="errorMessages.orderDate || []" type="date" outlined="outlined" dense="dense"
          v-bind="fieldAttributes.orderDate"></v-text-field>
      </v-col>
      <v-col lg="3" cols="12">
        <v-text-field hide-details="auto" label="Scheduled Delivery Date" v-model="deliveryDate"
          :error-messages="errorMessages.deliveryDate || []" clearable="clearable" outlined="outlined" dense="dense"
          type="date" v-bind="fieldAttributes.scheduledDeliveryDate"></v-text-field>
      </v-col>
      <v-col lg="3" cols="12">
        <v-text-field hide-details="auto" label="Actual Delivery Date" type="date" v-model="actualDeliveryDate"
          :error-messages="errorMessages.actualDeliveryDate || []" clearable="clearable" outlined="outlined"
          dense="dense" v-bind="fieldAttributes.actualDeliveryDate"></v-text-field>
      </v-col>
      <v-col lg="3" cols="12">
        <v-text-field hide-details="auto" label="Paid Date" type="date" v-model="paidDate" disabled="disabled"
          outlined="outlined" dense="dense" :error-messages="errorMessages.paidDate || []" clearable="clearable"
          v-bind="fieldAttributes.paidDate"></v-text-field>
      </v-col>
      <v-col lg="3" cols="12">
        <v-text-field hide-details="auto" label="Address" v-model="address" outlined="outlined" dense="dense"
          :error-messages="errorMessages.address || []" v-bind="fieldAttributes.address">
          <template v-slot:append>
            <v-btn v-if="address === defaultFieldValues.address" text x-small color="blue-grey darken-1"
              @click="address = getCustomerAddress()">
              <v-icon>mdi-folder</v-icon>
            </v-btn>
            <v-btn v-else text x-small color="blue-grey darken-1" @click="updateAddress"><v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col lg="3" cols="12">
        <v-select hide-details="auto" outlined dense label="Order Type" v-model="orderType"
          :error-messages="errorMessages.order_type || []" :items="['other', 'services', 'materials']"></v-select>
      </v-col>
      <v-col lg="3" cols="12">
        <v-text-field hide-details="auto" label="Shipping Fee" v-model="shippingFee" type="number" outlined="outlined"
          dense="dense" :error-messages="errorMessages.shippingFee || []" clearable="clearable"
          v-bind="fieldAttributes.shippingFee"></v-text-field>
      </v-col>
      <v-col lg="3" cols="12">
        <v-autocomplete hide-details="auto" label="Order Status" :items="computedOrderStatusList" item-value="id"
          item-text="name" outlined="outlined" dense="dense" :disabled="['CP', 'PL'].includes(orderStatusId)"
          v-model="orderStatusId" :error-messages="errorMessages.orderStatusId || []" clearable="clearable"
          v-bind="fieldAttributes.orderStatus"></v-autocomplete>
      </v-col>
      <v-col lg="3">
        <v-row>
          <v-col cols="12">
            <v-autocomplete hide-details="auto" label="Payment Type" v-model="paymentType" outlined="outlined"
              dense="dense" :error-messages="errorMessages.paymentType || []" clearable="clearable"
              :items="['Cash', 'Check']" v-bind="fieldAttributes.paymentType"></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3">
        <v-autocomplete hide-details="auto" label="Payment Terms" v-model="paymentTerms" outlined="outlined"
          dense="dense" :error-messages="errorMessages.paymentTerms || []" clearable="clearable" :items="[0, 7, 15, 30]"
          v-bind="fieldAttributes.paymentTerms"><template v-slot:selection="{ item }"><span v-if="!item">COD</span><span
              v-else>{{ item }} days</span></template><template v-slot:item="{ item }"><span
              v-if="!item">COD</span><span v-else>{{ item }} days</span></template></v-autocomplete>
      </v-col>
      <v-col cols="3" class="checkbox-input">
        <v-checkbox label="VAT (12%)" class="mt-0" v-model="vat"></v-checkbox>
        <v-checkbox label="Show Discount" class="mt-0 ml-2" v-model="showDiscount"></v-checkbox>
      </v-col>
      <v-col lg="9" cols="12">
        <v-textarea outlined="outlined" no-resize="no-resize" label="Notes" v-model="notes"
          :error-messages="errorMessages.notes || []" clearable="clearable" v-bind="fieldAttributes.notes"></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>
<style lang="sass" scope>
.checkbox-input
  display: flex
  align-items: center

</style>
<script>
// import indexKeyGenerator from '@/libs/index-key-generator.extra'
import projectCodeRepository from '@/repositories/project-code.repository'
import customerRepository from '@/repositories/customer.repository'
import orderStatusRepository from '@/repositories/order-status.repository'
import employeeRepository from '@/repositories/employee.repository'
import VueGetApi from '@/libs/classes/VueGetApi.class'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { getVars, requestVars } from '@/libs/api-helper.extra'

const inputVars = () => ({
  id: null,
  poNumber: null,
  projectCodeId: null,
  customerId: null,
  orderDate: Window.getCurrentDate(),
  deliveryDate: Window.getCurrentDate(),
  actualDeliveryDate: null,
  deliveredBy: null,
  coDeliverer: null,
  address: null,
  shippingFee: null,
  paymentType: 'Cash',
  paidDate: null,
  notes: null,
  orderStatusId: 'NW',
  paymentTerms: 0,
  vat: false,
  orderType: 'other',
  showDiscount: false,
})

const [projectCodeIndexVars, projectCodeIndexVarNames] = requestVars({ identifier: 'project-code-index', pagination: true })

const projectCodeIndexHandler = new VueRequestHandler(null, projectCodeIndexVarNames, {}, true)
// const disableExceptNew = sequence => item => {
//   if (item.sequence === sequence) {
//     return item
//   }
//   return { ...item, disabled: true }
// }

// const disableGreaterEqualSequence = sequence => item => {
//   if (item.sequence >= sequence) {
//     return item
//   }
//   return { ...item, disabled: true }
// }

export default {
  name: 'OrderForm',
  props: {
    inputFields: {
      type: Object,
      default: inputVars,
    },
    defaultFieldValues: {
      type: Object,
      default: inputVars,
    },
    errorMessages: {
      type: Object,
      default: () => ({}),
    },
    fieldAttributes: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    this.getOrderStatus()
    this.getCustomers()
    this.getEmployees()
    this.listenWebsocketEvents()
    this.updateInputFieldsProps()
    this.getProjectCodes()
  },
  data: () => ({
    ...inputVars(),
    ...projectCodeIndexVars,
    searchProjectCode: '',
    ...getVars('order-status'),
    ...getVars('customer'),
    ...getVars('employee'),
  }),
  computed: {
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown
    },
    selectedCustomer() {
      const id = this.customerId
      return this.customerList.find(customer => customer.id === id)
    },
    computedOrderStatusList() {
      const { orderStatusId = null, status = {} } = this.defaultFieldValues
      const { sequence = 1 } = status
      return this.orderStatusList.filter(item => !['CP', 'PL', 'RF'].includes(item.id))
      // if (!orderStatusId) return this.orderStatusList.map(disableExceptNew(sequence))
      // return this.orderStatusList.filter(disableGreaterEqualSequence(sequence))
    },
    computedEmployeeList() {
      const { deliveredBy, coDeliverer } = this
      return this.employeeList.map(item => {
        if (deliveredBy === item.id || coDeliverer === item.id) {
          return { ...item, disabled: true }
        }
        return item
      })
    },
  },
  watch: {
    // inputFields (item) {
    //   this.populateFields(item)
    // },
    searchProjectCode() {
      this.getProjectCodes()
    },
    defaultFieldValues(item) {
      this.populateFields(item)
    },
    id() {
      this.updateInputFieldsProps()
    },
    selectedCustomer() {
      this.updateAddress()
      this.updateInputFieldsProps()
    },
    orderDate() {
      this.updateInputFieldsProps()
    },
    deliveryDate() {
      this.updateInputFieldsProps()
    },
    actualDeliveryDate() {
      this.updateInputFieldsProps()
    },
    deliveredBy() {
      this.updateInputFieldsProps()
    },
    coDeliverer() {
      this.updateInputFieldsProps()
    },
    shippingFee() {
      this.updateInputFieldsProps()
    },
    address() {
      this.updateInputFieldsProps()
    },
    paymentType() {
      this.updateInputFieldsProps()
    },
    paidDate() {
      this.updateInputFieldsProps()
    },
    notes() {
      this.updateInputFieldsProps()
    },
    orderStatusId() {
      this.updateInputFieldsProps()
    },
    paymentTerms() {
      this.updateInputFieldsProps()
    },
    poNumber() {
      this.updateInputFieldsProps()
    },
    vat() {
      this.updateInputFieldsProps()
    },
    showDiscount() {
      this.updateInputFieldsProps()
    },
  },
  methods: {
    employeeFilter(item, queryText) {
      const name = this.getName(item)
      const text = `${item.id}${name}`
      const regex = new RegExp(queryText, 'i')
      return regex.test(text)
    },
    updateAddress() {
      const defaultCustomerId = this.defaultFieldValues.customerId || null
      if (!this.customerId) return
      if (this.customerId === defaultCustomerId) {
        this.address = this.defaultFieldValues.address
        return
      }
      this.address = this.getCustomerAddress()
    },
    filterCustomerList(item, queryText) {
      const regExp = new RegExp(queryText, 'i')
      return regExp.test(item.id) || regExp.test(item.company)
    },
    getCustomerAddress() {
      const {
        houseNo = null,
        street = null,
        purok = null,
        brgy = null,
        city = null,
        province = null,
      } = this.$objectChangeKeyCase(this.selectedCustomer)
      return [houseNo, street, purok, brgy, city, province]
        .filter(item => Boolean(item))
        .join(', ')
    },
    populateFields(item) {
      Object.keys(item).forEach(key => {
        this[key] = item[key]
      })
    },
    updateInputFieldsProps() {
      const fieldValues = this.fieldValues()
      this.$emit('update:inputFields', fieldValues)
    },
    getCustomers() {
      if (this.customerGetting) return
      const requestApiVarNames = this.getGetVarsKeys('customer')
      const requestPostApi = new VueGetApi(this, requestApiVarNames)
      const repository = customerRepository.index
      requestPostApi.execute(repository)
    },
    getEmployees() {
      if (this.employeeGetting) return
      const requestVarNames = this.getGetVarsKeys('employee')
      const vueGetApi = new VueGetApi(this, requestVarNames)
      const repository = employeeRepository.all
      vueGetApi.execute(repository)
    },
    getOrderStatus() {
      if (this.orderStatusGetting) return
      const requestVariableNames = this.getGetVarsKeys('orderStatus')
      const requestGetApi = new VueGetApi(this, requestVariableNames)
      const repository = orderStatusRepository.index()
      requestGetApi.fetch(repository)
    },
    fieldValues() {
      const result = inputVars()
      Object.keys(result).forEach(key => {
        result[key] = this[key]
      })
      return result
    },
    getGetVarsKeys(baseName) {
      return {
        loading: `${baseName}Getting`,
        error: `${baseName}GetErrors`,
        list: `${baseName}List`,
      }
    },
    getProjectCodes() {
      const handler = projectCodeIndexHandler
      const repository = projectCodeRepository.index
      const params = {
        search: this.searchProjectCode,
      }
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    listenWebsocketEvents() {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.dbStoreEvent)
    },
    dbStoreEvent({ model, data }) {
      if (!model || this.$objectEmpty(data)) return
      this.executeAppropriateDBStoreEvent({ model, data })
    },
    executeAppropriateDBStoreEvent({ model, data }) {
      if (model === 'Customer') {
        this.customerDBStoreEvent(data)
        return
      }
      if (model === 'OrderStatus') {
        this.orderStatusDBStoreEvent(data)
        return
      }
      if (model === 'Employees') {
        this.employeeDBStoreEvent(data)
      }
    },
    orderStatusDBStoreEvent(data) {
      this.insertToReactiveList('orderStatusList', data, 'id', 'id')
    },
    customerDBStoreEvent(data) {
      this.insertToReactiveList('customerList', data, 'id', 'id')
    },
    employeeDBStoreEvent(data) {
      this.insertToReactiveList('employeeList', data, 'id', 'id')
    },
    insertToReactiveList(attribute, ...args) {
      this[attribute] = this[attribute].objectInsertion(...args)
        .map(item => ({ ...item }))
    },
    getName(args) {
      const {
        firstname = null,
        lastname = null,
        middlename = null,
      } = args
      const middleInitial = middlename ? `${middlename.substr(0, 1).toUpperCase()}.` : null
      return [firstname, middleInitial, lastname]
        .filter(item => Boolean(item))
        .join(' ')
    },

  },
}
</script>
